<template src="./dashboard-bar.html"></template>

<script>
import Datepicker from "vuejs-datepicker";
import ClickOutside from "vue-click-outside";

export default {
  name: "dashboard-bar",
  props: ["pageName"],
  components: {
    Datepicker
  },
  data() {
    return {
      dateDropDown: false,
      dateDropDownMobile: false,
      tableSearchField: false,
      tableSearchFieldMobile: false
    };
  },
  methods: {
    dateDropDownOpen() {
      this.dateDropDown = true;
    },
    dateDropDownClose() {
      this.dateDropDown = false;
    },
    dateDropDownOpenMobile() {
      this.dateDropDownMobile = true;
    },
    dateDropDownCloseMobile() {
      this.dateDropDownMobile = false;
    },
    tableSearchFieldOpen() {
      this.tableSearchField = true;
    },
    tableSearchFieldClose() {
      this.tableSearchField = false;
    },
    tableSearchFieldMobileOpen() {
      this.tableSearchFieldMobile = true;
    },
    tableSearchFieldMobileClose() {
      this.tableSearchFieldMobile = false;
    }
  },
  mounted() {
    // prevent click outside event with popupItem.
    // this.popupItem = this.$el
  },

  // do not forget this section
  directives: {
    ClickOutside
  }
};
</script>
<style src="./dashboard-bar.css" scoped></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin-div {
  margin-top: -65px;
}
</style>
