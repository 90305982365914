<template src="./pagination.html"> </template>

<script>
export default {
  name: "pagination",
  props: {
    pageCount: {
      type: Number,
      default: 0,
      description:
        "Pagination page count. This should be specified in combination with perPage"
    },
    perPage: {
      type: Number,
      default: 10,
      description:
        "Pagination per page. Should be specified with total or pageCount"
    },
    total: {
      type: Number,
      default: 0,
      description:
        "Can be specified instead of pageCount. The page count in this case will be total/perPage"
    },
    value: {
      type: Number,
      default: 1,
      description: "Pagination value"
    }
  },
  computed: {
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    // pagesToDisplay() {
    //   if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
    //     return this.totalPages;
    //   }
    //   return this.defaultPagesToDisplay;
    // },
    // minPage() {
    //   if (this.value >= this.pagesToDisplay) {
    //     const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
    //     const newMaxPage = pagesToAdd + this.value;
    //     if (newMaxPage > this.totalPages) {
    //       return this.totalPages - this.pagesToDisplay + 1;
    //     }
    //     return this.value - pagesToAdd;
    //   } else {
    //     return 1;
    //   }
    // },
    // maxPage() {
    //   if (this.value >= this.pagesToDisplay) {
    //     const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
    //     const newMaxPage = pagesToAdd + this.value;
    //     if (newMaxPage < this.totalPages) {
    //       return newMaxPage;
    //     } else {
    //       return this.totalPages;
    //     }
    //   } else {
    //     return this.pagesToDisplay;
    //   }
    // },
    pages() {
      const total = this.totalPages;
      const current = this.value;
      const delta = 2;
      const range = [];

      if (total <= 7) {
        for (let i = 1; i <= total; i++) {
          range.push(i);
        }
      } else {
        let start = Math.max(current - delta, 1);
        let end = Math.min(current + delta, total);

        if (current - delta <= 2) {
          end = 5;
        }

        if (current + delta >= total - 1) {
          start = total - 4;
        }

        for (let i = start; i <= end; i++) {
          range.push(i);
        }

        if (start > 2) {
          range.unshift('...');
          range.unshift(1);
        } else if (start === 2) {
          range.unshift(1);
        }

        if (end < total - 1) {
          range.push('...');
          range.push(total);
        } else if (end === total - 1) {
          range.push(total);
        }
      }

      return range;
    }
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
      newVal: 1
    };
  },
  methods: {
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage(item) {
      if (item === '...') return;
      this.newVal = item;
      this.$emit("input", item);
    },
    nextPage() {
      if (this.value < this.totalPages) {
        this.$emit("input", this.value + 1);
        this.newVal = this.value + 1;
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
        this.newVal = this.value - 1;
      }
    },
    first() {
      this.value = 1;
      this.newVal = 1;
      this.$emit("input", this.value);
    },
    last() {
      this.value = this.pageCount;
      this.newVal = this.pageCount;
      this.$emit("input", this.value);
    }
  },
  watch: {
    perPage() {
      this.$emit("input", 1);
    },
    total() {
      this.$emit("input", 1);
    }
  }
};
</script>

<style src="./pagination.css" scoped></style>
