<template src="./topic-wise.html"></template>

<script>
import VueSpeedometer from "vue-speedometer";
import appHeader from "../../shared-module/components/app-header/app-header.vue";
import appFooter from "../../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "../../shared-module/components/app-sidebar/app-sidebar.vue";
import dashBoardBar from "../../shared-module/components/dashboard-bar/dashboard-bar.vue";
// import Datepicker from "vuejs-datepicker";
import ClickOutside from "vue-click-outside";

export default {
  name: "dashboard-courses",
  components: {
    VueSpeedometer,
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    "dashboard-bar": dashBoardBar
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      customSegmentLabels: [
        {
          text: "20%",
          position: "INSIDE",
          color: "#fff"
        },
        {
          text: "40%",
          position: "INSIDE",
          color: "#fff"
        },
        {
          text: "60%",
          position: "INSIDE",
          color: "#fff"
          // fontSize: "19px"
        },
        {
          text: "80%",
          position: "INSIDE",
          color: "#fff"
        },
        {
          text: "100%",
          position: "INSIDE",
          color: "#fff"
        }
      ],
      segmentColors: ["#D01830", "#F79329", "#FDEB48", "#99C817", "#219653"]
    };
  },
  methods: {},
  async mounted() {}
};
</script>
<style src="./topic-wise.css" scoped></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin-div {
  margin-top: -65px;
}
</style>
