<template src="./card-login.html"></template>

<script>
export default {
  name: "card-login",
  data() {
    return {
      course: false,
      liveSession: false,
      quiz: true,
      assignment: false,
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<style src="./card-login.css"></style>
