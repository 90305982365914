<template src="./discussion.html"></template>

<script>
import appHeader from "./../../shared-module/components/app-header/app-header.vue";
import appFooter from "./../../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "./../../shared-module/components/app-sidebar/app-sidebar.vue";
import AppVerification from "./../../shared-module/components/app-verification/app-verification";
import dashBoardBar from "../../shared-module/components/dashboard-bar/dashboard-bar.vue";
// import Datepicker from "vuejs-datepicker";
// import ClickOutside from "vue-click-outside";

export default {
  name: "content-watched",
  components: {
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    "app-verification": AppVerification,
    "dashboard-bar": dashBoardBar
    // Datepicker
  },
  // directives: {
  //   ClickOutside
  // },
  data() {
    return {
      // dateDropDown: false,
      // tableSearchField: false
    };
  },
  methods: {
    // dateDropDownOpen() {
    //   this.dateDropDown = !this.dateDropDown;
    // },
    // dateDropDownClose() {
    //   this.dateDropDown = false;
    // },
    // tableSearchFieldOpen() {
    //   this.tableSearchField = !this.tableSearchField;
    // },
    // tableSearchFieldClose() {
    //   this.tableSearchField = false;
    // }
  },
  async mounted() {}
};
</script>
<style src="./discussion.css" scoped></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin-div {
  margin-top: -65px;
}
</style>
