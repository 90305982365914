<template src="./dashboard-courses.html"></template>

<script>
import appHeader from "../../shared-module/components/app-header/app-header.vue";
import appFooter from "../../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "../../shared-module/components/app-sidebar/app-sidebar.vue";
import AppVerification from "./../../shared-module/components/app-verification/app-verification";
import dashBoardBar from "./../../shared-module/components/dashboard-bar/dashboard-bar.vue";
// import Datepicker from "vuejs-datepicker";
import ClickOutside from "vue-click-outside";

export default {
  name: "dashboard-courses",
  components: {
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    "app-verification": AppVerification,
    "dashboard-bar": dashBoardBar
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      dateDropDown: false,
      tableSearchField: false
    };
  },
  methods: {},
  async mounted() {}
};
</script>
<style src="./dashboard-courses.css" scoped></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin-div {
  margin-top: -65px;
}
</style>
