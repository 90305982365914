<template src="./app-login.html"></template>

<script>
import AppLogin from "./../../../app-login-page/login-page.vue";
import CustomStorageService from "../../../app-services/custom-storage-service";

export default {
  name: "app-login",
  props: {
    type: String,
    mandatory: Boolean,
  },
  components: {
    "app-login-page": AppLogin
  },
  methods: {
    openSignupPopup() {
      this.openLoginPage = true;
    },
    closeLoginPage() {
      if (this.$route.query.user == "false") {
        this.$router.push({
          query: { sessionId: this.$route.query.sessionId }
        });
      }
      this.openLoginPage = false;
    },
    onLogin() {
      this.$emit("onLogin");
    }
  },

  data() {
    return {
      openLoginPage: false,
    };
  },
  async mounted() {
    // let loogedInUser = CustomStorageService.getUser();
    // if (!loogedInUser) {
    //   this.openLoginPage = true;
    // }
  },
  created() {
    // this.openLoginPage = true;
  }
};
</script>

<style src="./app-login.css" scoped></style>
