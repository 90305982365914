<template src="./dashboard.html"></template>

<script>
import appHeader from "./../shared-module/components/app-header/app-header.vue";
import appFooter from "./../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "./../shared-module/components/app-sidebar/app-sidebar.vue";
import AppVerification from "./../shared-module/components/app-verification/app-verification";
import ClickOutside from "vue-click-outside";
import VueApexCharts from "vue-apexcharts";
import Datepicker from "vuejs-datepicker";
// import ApexCharts from 'apexcharts';
// import VueApexCharts from 'vue-apexcharts'

export default {
  name: "dashboard",
  components: {
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    "app-verification": AppVerification,
    apexchart: VueApexCharts,
    Datepicker
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      leadershipOverAll:false,
      calenderDateDD: false,
      timeSpent:false,
      quizPerformanceOverAll:false,
      courseStats: [70],
      quizPerfomance: [50],
      courseStatschart: {
        chart: {
          // height: 140,
          type: "radialBar"
        },
        colors: ["#857EFF"],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
              imageWidth: 150,
              imageHeight: 150
            },
            track: {
              show: true,
              background: "#E3E8F1"
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                color: "#000",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#A0A1FD"],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["asdf"]
      },
      quizPerformanceChart: {
        chart: {
          // height: 140,
          type: "radialBar"
        },
        colors: ["#857EFF"],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
              imageWidth: 150,
              imageHeight: 150
            },
            track: {
              show: true,
              background: "#E3E8F1"
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                color: "#ED960B",
                fontSize: "50px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "solid",
          colors: ['#FFBA4D']
          // gradient: {
          //   shade: "dark",
          //   type: "vertical",
          //   gradientToColors: ["#FFBA4D"],
          //   stops: [0, 100]
          // }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["asdf"]
      },
      series: [
        {
          name: "series1",
          data: [31, 40, 28, 51, 42, 109, 100]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false
          },
          toolbar: {
            autoSelected: "pan",
            show: false
          }
        },
        grid: {
          borderColor: "rgba(0,0,0,0.1)",
          clipMarkers: false,
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        colors: ["#E03249"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
       xaxis: {
              categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            },

        markers: {
          size: 5,
          colors: ["#828282"],
          strokeColor: "#828282",
          strokeWidth: 3
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm"
          }
        }
      }
    };
  },
  methods: {
    calenderDateOpen() {
      this.calenderDateDD = !this.calenderDateDD;
    },
    timeSpentOpen() {
      this.timeSpent = !this.timeSpent;
    },
    leadershipOverAllopen(){
      console.log('0000000000000----------');
      this.leadershipOverAll = !this.leadershipOverAll;
    },

    leadershipOverAllClose(){
      console.log('0000000000000----------');
      this.leadershipOverAll = false;
    },
    
    quizPerformanceOverAllopen(){
      console.log('yyyyyyyyyywwwwwwwww', this.quizPerformanceOverAll);
      this.quizPerformanceOverAll = !this.quizPerformanceOverAll;
    },
    quizPerformanceOverAllClose(){
      console.log('nnnnnnnnnnnnnnn');
       this.quizPerformanceOverAll = false
    }
    // calenderDateOpen(){
    //   console.log('working..........');
    //   this.calenderDateDD = !this.calenderDateDD;
    //   console.log('working..........eeeeeeeeeeeee');
    // }
  },
  mounted() {
    // this.popupItem = this.$el
  }
};
</script>
<style src="./dashboard.css" scoped></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin-div {
  margin-top: -65px;
}
</style>
